// @ts-strict-ignore
import { IQpPageQuery } from '@library/classes/qp-page/qp-page.models';
import { IQpDatatableFilterAndSortParam } from '@library/components/qp-datatable/qp-datatable.models';
import { IMeasurementSampling } from '@library/dto/checklist/measure/simplified/measurement-sampling.interface';
import { SimplifiedMeasurementChecklistMeasuresDTO } from '@library/dto/checklist/measure/simplified/simplified-measurement-checklist-measures.dto';
import { SimplifiedMeasurementChecklistStatusDTO } from '@library/dto/checklist/measure/simplified/simplified-measurement-checklist-status.dto';
import { AbortInspectionDTO } from '@library/dto/inspection/abort-inspection.dto';
import { CreateInspectionDTO } from '@library/dto/inspection/create-inspection.dto';
import { InspectionChangeRequestDTO } from '@library/dto/inspection/inspection-change-request.dto';
import { InspectionTypeDTO } from '@library/dto/inspection-type.dto';
import { IInspectionSamplingSizesDTO } from '@library/dto/workflow/sampling-size.dto';
import { AqlValue } from '@library/dto-enums/aql-value.dto-enum';
import { CartonCalculationMethod } from '@library/dto-enums/carton-calculation-method.dto-enum';
import { DefectClassification } from '@library/dto-enums/defect-classification.dto-enum';
import { InspectionStatus } from '@library/dto-enums/inspection-status.dto-enum';
import { qpParseInt } from '@library/functions/math/qp-parse-int';
import { QpUuidType } from '@library/models/qp-alias.models';
import { EQpAQLInspectionLevel } from '@library/models/qp-aql.models';
import { IQpConsultationPage, QpConsultationPage } from '@library/models/qp-consultation-page.models';
import { IQpCoordinates } from '@library/models/qp-coordinates.models';
import { EQpImageElementType, EQpImageThumbnailSize } from '@library/models/qp-image.models';
import { QpDateService } from '@library/services/qp-date/qp-date.service';
import { QpLoggerService } from '@library/services/qp-logger/qp-logger.service';
import { QpPictureCompressionService } from '@library/services/qp-picture-compression/qp-picture-compression.service';
import { QpResponsiveService } from '@library/services/qp-responsive/qp-responsive.service';
import { SERVER_API_URL } from '@one/app/app.constants';
import {
  IInspectionFilteringParams,
  IInspectionPageQuery,
} from '@one/app/pages/brd/pages/inspection/pages/consult/brd-inspection-consult.component.models';
import { ICreateInspectionDTO } from '@one/app/pages/brd/pages/inspection/pages/create-v2/brd-inspection-create-v2.dto';
import { BrdInspectionEdition } from '@one/app/pages/brd/pages/inspection/shared/classes/brd-inspection';
import {
  IBrdInspectionEdition,
  IInspectionEditionDto,
  IInspectionProduct,
} from '@one/app/pages/brd/pages/inspection/shared/models/brd-inspection.models';
import { IESInspectionConsultation } from '@one/app/pages/brd/shared/models/es-inspection-consultation.model';
import { EntityConsultationService } from '@one/app/pages/brd/shared/services/entity-consultation.service';
import { CountsPerType, IInspectionsSummary } from '@one/app/pages/isp/pages/home/isp-home.models';
import { IDefectsChecklistDefect } from '@one/app/pages/isp/pages/inspection/pages/id/pages/defects-checklist/isp-inspection-id-defect.models';
import { EInspectionDocumentType } from '@one/app/pages/isp/pages/inspection/pages/id/shared/components/instruction-documents/instruction-documents.models';
import { IIspInspectionIdStepCompletionTime } from '@one/app/pages/isp/pages/inspection/pages/id/shared/models/isp-inspection-id-step-completion-time.models';
import { IDeleteWorkflowImage } from '@one/app/pages/isp/pages/inspection/pages/id/shared/services/store/isp-inspection-id-workflow-store.actions';
import WorkflowUtils from '@one/app/pages/isp/pages/inspection/pages/id/shared/services/workflow.utils';
import { InspectionId } from '@one/app/pages/isp/shared/models/isp-inspection.models';
import { InspectionConsultation } from '@one/app/shared/classes/inspection-consultation/inspection-consultation';
import { HTTP_SKIP_404_OPTIONS } from '@one/app/shared/constants/http-skip-404-options';
import { getCustomHttpHeaders } from '@one/app/shared/functions/get-custom-http-headers/get-custom-http-headers';
import { getGeneratedEntityId } from '@one/app/shared/functions/get-generated-entity-id/get-generated-entity-id';
import { INTERCEPTOR_SKIP_HEADER } from '@one/app/shared/interceptors/inspection.interceptor';
import { IMaxDefectsAllowed } from '@one/app/shared/models/defects/defects.models';
import { IEntityWorkflowTemplateStepAction } from '@one/app/shared/models/entity/entity-workflows-templates.models';
import { EHttpQimaEndpoints, HTTP_QIMA_ENDPOINT_NAME, IHttpOptions } from '@one/app/shared/models/http/http.models';
import {
  EChecklistImageType,
  IChecklistComment,
  IChecklistImageData,
  IInspectionConsultation,
  InspectionStatusDTO,
} from '@one/app/shared/models/inspection-consultation/inspection-consultation.models';
import { Picture } from '@one/app/shared/models/picture/picture.models';
import { IReportRevision } from '@one/app/shared/models/report/report-revision.models';
import { EReportDecision, EReportOrigin } from '@one/app/shared/models/report/report.models';
import { ISamplingSize } from '@one/app/shared/models/sampling/sampling-size.models';
import {
  IFindingsSummaryReviewConfirmationDTO,
  INewDefectInfo,
  IProductActualQuantities,
  IWorkflow,
} from '@one/app/shared/models/workflow/workflow.models';
import {
  IWorkflowTemplateStepAction,
  IWorkflowTemplateStepActionDocument,
} from '@one/app/shared/models/workflows/workflows-templates.models';
import {
  IInspectionServiceGetInspectionElementImageData,
  IInspectionServiceGetInspectionImageData,
} from '@one/app/shared/services/inspection/inspection.service.models';
import { uuid } from '@one/app/shared/types/commons.types';
import { HttpClient, HttpHeaders, HttpParams, HttpResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { QimaOptionalType } from '@qima/ngx-qima';
import { fromBase64 } from 'js-base64';
import { isEmpty, isNil } from 'lodash/index';
import { BehaviorSubject, from, Observable, throwError } from 'rxjs';
import { catchError, finalize, map, mergeMap } from 'rxjs/operators';
import { v4 as uuidv4 } from 'uuid';

@Injectable({
  providedIn: 'root',
})
export class InspectionService {
  // 💡 This variable stores the number of items currently being saved in IndexedDB.
  // This allows to avoid closing an inspection if there are still items to save in IndexedDB.
  public readonly numberOfPendingItemsToSave$: BehaviorSubject<number> = new BehaviorSubject<number>(0);

  public readonly inspectionTypeSubject$: BehaviorSubject<InspectionTypeDTO[]> = new BehaviorSubject<InspectionTypeDTO[]>([]);
  private readonly _resourceUrl = `${SERVER_API_URL}api/inspections`;

  public constructor(
    private readonly _httpClient: HttpClient,
    private readonly _qpDateService: QpDateService,
    private readonly _qpResponsiveService: QpResponsiveService,
    private readonly _qpLoggerService: QpLoggerService,
    private readonly _imageCompressionService: QpPictureCompressionService,
    private readonly _entityConsultationService: EntityConsultationService
  ) {}

  public createInspectionFromCreateInspectionDTO$(inspection: CreateInspectionDTO): Observable<IBrdInspectionEdition> {
    // TODO: migrate the return to InspectionDTO once exist in frontend
    const url = `${this._resourceUrl}`;

    return this._httpClient.post<IBrdInspectionEdition>(url, inspection);
  }

  public createInspection$(inspection: IBrdInspectionEdition): Observable<IBrdInspectionEdition> {
    const url = `${this._resourceUrl}`;

    inspection.updateDate = QpDateService.now;
    inspection.updateTimezone = QpDateService.timezone;

    return this._httpClient.post<IBrdInspectionEdition>(url, inspection);
  }

  // TODO: BE only uses CreateInspectionDTO, get rid of all duplicated enums, interfaces, types like ICreateInspectionDTO
  public createInspectionFromBookingV2$(inspection: ICreateInspectionDTO): Observable<IBrdInspectionEdition> {
    const url = `${this._resourceUrl}`;

    return this._httpClient.post<IBrdInspectionEdition>(url, inspection);
  }

  public updateInspectionFromBookingV2$(inspection: ICreateInspectionDTO, inspectionId: number): Observable<void> {
    const url = `${this._resourceUrl}/${inspectionId}`;

    inspection.updateDate = QpDateService.now;
    inspection.updateTimezone = QpDateService.timezone;

    return this._httpClient.put<void>(url, inspection);
  }

  public updateInspectionStatus$(
    id: InspectionId,
    status: InspectionStatus,
    location: QimaOptionalType<IQpCoordinates> = null,
    updateDate: Date,
    updateTimezone: string,
    cancellationReasons: string[] = [],
    cancellationComment: string | null = null
  ): Observable<unknown> {
    const url = `${this._resourceUrl}/${id}/status`;

    return this._httpClient.patch(
      url,
      new InspectionStatusDTO(status, location, updateDate, updateTimezone, cancellationReasons, cancellationComment),
      { observe: 'body' }
    );
  }

  public requestChangeRequest$(id: Readonly<InspectionId>, reportRevision: IReportRevision): Observable<void> {
    const url = `${this._resourceUrl}/${id}/change-request`;

    return this._httpClient.post<void>(url, reportRevision);
  }

  public getChangeRequests$(inspectionId: Readonly<InspectionId>): Observable<InspectionChangeRequestDTO[]> {
    const url = `${this._resourceUrl}/${inspectionId}/change-requests`;

    return this._httpClient.get<InspectionChangeRequestDTO[]>(url);
  }

  public getInspection$(id: Readonly<InspectionId>): Observable<IInspectionConsultation> {
    const url = `${this._resourceUrl}/${id}`;

    return this._httpClient
      .get<IInspectionConsultation>(url, {
        observe: 'body',
      })
      .pipe(
        map((inspection: Readonly<IInspectionConsultation>): InspectionConsultation => {
          return InspectionConsultation.createFromInterface(inspection);
        })
      );
  }

  public getInspectionSummary$(id: InspectionId): Observable<IBrdInspectionEdition> {
    const url = `${this._resourceUrl}/${id}/summary`;

    return this._httpClient.get<IInspectionEditionDto>(url, { observe: 'body' }).pipe(map(BrdInspectionEdition.createFromDto));
  }

  public putInspection$(inspection: IBrdInspectionEdition, inspectionId: number): Observable<IBrdInspectionEdition> {
    const url = `${this._resourceUrl}/${inspectionId}`;

    inspection.updateDate = this._qpDateService.getDate();
    inspection.updateTimezone = this._qpDateService.getTimezone();

    return this._httpClient.put<IBrdInspectionEdition>(url, inspection, { observe: 'body' });
  }

  public updateAqlReasonForChangesByInspectionId$(
    inspectionId: Readonly<InspectionId>,
    reason: Readonly<string>,
    purchaseOrderProductId?: QimaOptionalType<number>
  ): Observable<void> {
    const url = `${this._resourceUrl}/${inspectionId}/aql/reason`;

    return this._httpClient.put<void>(url, { reason, purchaseOrderProductId });
  }

  public getUnreviewReportsAsPage$(pageQuery: IQpPageQuery): Observable<IQpConsultationPage<IESInspectionConsultation>> {
    const params = new HttpParams().appendAll({
      filter: [`reportDecision,EQUALS,${EReportDecision.PENDING}`, `origin,EQUALS,${EReportOrigin.INTERNAL}`],
      page: String(pageQuery.page),
      size: String(pageQuery.size),
      sort: 'inspectionDate,desc',
    });

    return this._httpClient
      .get<IESInspectionConsultation[]>(`${this._resourceUrl}/reports`, {
        params,
        observe: 'response',
      })
      .pipe(
        map((response): IQpConsultationPage<IESInspectionConsultation> => {
          const inspections: IESInspectionConsultation[] = response.body ?? [];
          const totalItems = qpParseInt(response.headers.get('X-Total-Count') || '0');
          const totalPageCount = qpParseInt(response.headers.get('x-total-page') || '0');

          return QpConsultationPage.create<IESInspectionConsultation>({
            items: inspections,
            page: pageQuery.page + 1,
            pageSize: pageQuery.size,
            totalItems,
            totalPageCount,
          });
        })
      );
  }

  public getInspectionsAsPage$(
    pageQuery: IInspectionPageQuery,
    filters?: IInspectionFilteringParams
  ): Observable<IQpConsultationPage<IESInspectionConsultation>> {
    const filterAndSortsParams: IQpDatatableFilterAndSortParam[] = [];

    if (filters?.fromDate && filters?.toDate) {
      filterAndSortsParams.push({
        type: 'filter',
        value: `plannedDate,BETWEEN,[${this._qpDateService.toLocaleDate(filters.fromDate)},${this._qpDateService.toLocaleDate(
          filters.toDate
        )},${QpDateService.timezone}]`,
      });
    }

    if (filters?.status) {
      filterAndSortsParams.push({
        type: 'filter',
        value: `status,IN,[${filters.status.toString()}]`,
      });
    }

    return this._entityConsultationService.getInspections$(pageQuery, filterAndSortsParams);
  }

  public getInspectionPicture$({
    inspectionId,
    imageId,
    imageType,
    isThumbnail = true,
    options,
  }: IInspectionServiceGetInspectionImageData): Observable<Picture> {
    const url = `${this._resourceUrl}/${inspectionId}/images/${imageType}/${imageId}`;
    const size = this._getThumbnailsSize();
    const thumbnailParam = imageType === EChecklistImageType.SCAN ? {} : { thumbnail: size };

    return this._httpClient
      .get(url, {
        responseType: 'arraybuffer',
        observe: 'response',
        params: isThumbnail ? thumbnailParam : null,
        headers: getCustomHttpHeaders(options),
      })
      .pipe(
        map((response): Picture => {
          return {
            id: imageId,
            type: response.headers.get('content-type'),
            rawData: response.body,
            data: JSON.parse(fromBase64(response.headers.get('data')) || '{}'),
          };
        })
      );
  }

  public updateInspectionComment$(inspectionId: InspectionId, commentData: IChecklistComment): Observable<void> {
    const url = `${this._resourceUrl}/${inspectionId}/comment`;

    return this._httpClient.patch<void>(url, commentData);
  }

  public updateInspectionIsMarkedAsPending$(inspectionId: InspectionId, isMarkedAsPending: boolean): Observable<void> {
    const url = `${this._resourceUrl}/${inspectionId}/result`;

    return this._httpClient.patch<void>(url, { pending: isMarkedAsPending });
  }

  public abortInspection$(inspectionId: InspectionId, isAborted: boolean): Observable<void> {
    const url = `${this._resourceUrl}/${inspectionId}/abort`;
    const body: AbortInspectionDTO = {
      isAborted,
      date: QpDateService.now,
      timezone: QpDateService.timezone,
    };

    return this._httpClient.patch<void>(url, body);
  }

  public getInspectionsCounts$(statuses: InspectionStatus[] = []): Observable<CountsPerType<InspectionStatus>> {
    let url = `${this._resourceUrl}/counts`;

    if (statuses.length > 0) {
      url += `?status=${statuses.join(',')}`;
    }

    return this._httpClient.get<CountsPerType<InspectionStatus>>(url);
  }

  public saveCartonsQuantity$(
    inspectionId: InspectionId,
    pickedCartons: QimaOptionalType<number>,
    totalCartons: QimaOptionalType<number>,
    cartonCalculationMethod: CartonCalculationMethod
  ): Observable<number> {
    const url = `${this._resourceUrl}/${inspectionId}/cartons`;

    return this._httpClient.patch<number>(url, {
      pickedCartons: pickedCartons ?? null,
      totalCartons: totalCartons ?? null,
      cartonCalculationMethod,
    });
  }

  /**
   * @description
   * Used for the AQL defects
   * @param {Readonly<number>} productQuantity
   * @param {Readonly<EQpAQLInspectionLevel>} inspectionLevel
   * @returns {Observable<ISamplingSize>}
   */
  public calculateExpectingSamplingSize$(
    productQuantity: Readonly<number>,
    inspectionLevel: Readonly<EQpAQLInspectionLevel>
  ): Observable<ISamplingSize> {
    const url = `${SERVER_API_URL}api/expected-sampling-sizes`;
    const headers = new HttpHeaders().append(HTTP_QIMA_ENDPOINT_NAME, EHttpQimaEndpoints.GET_EXPECTED_SAMPLING_SIZES);

    return this._httpClient.get<ISamplingSize>(url, {
      headers,
      params: new HttpParams({
        fromObject: {
          productQuantity: productQuantity.toString(),
          inspectionLevel,
        },
      }),
    });
  }

  /**
   * @description
   * Used for the AQL (except the defects)
   * @param {Readonly<number>} productQuantity
   * @param {Readonly<EQpAQLInspectionLevel>} inspectionLevel
   * @param {Readonly<AqlValue>} critical
   * @param {Readonly<AqlValue>} major
   * @param {Readonly<AqlValue>} minor
   * @returns {Observable<ISamplingSize>}
   */
  public calculateAQLSamplingSize$(
    productQuantity: Readonly<number>,
    inspectionLevel: Readonly<EQpAQLInspectionLevel>,
    critical: number | AqlValue,
    major: number | AqlValue,
    minor: number | AqlValue
  ): Observable<ISamplingSize> {
    const url = `${SERVER_API_URL}api/aql-sampling-sizes`;
    const headers = new HttpHeaders().append(HTTP_QIMA_ENDPOINT_NAME, EHttpQimaEndpoints.GET_AQL_SAMPLING_SIZES);

    return this._httpClient.get<ISamplingSize>(url, {
      headers,
      params: new HttpParams({
        fromObject: {
          productQuantity: productQuantity.toString(),
          inspectionLevel,
          critical,
          major,
          minor,
        },
      }),
    });
  }

  public updateInspectionSamplingSizes$(
    inspectionId: Readonly<InspectionId>,
    samplingSizes: Readonly<IInspectionSamplingSizesDTO>
  ): Observable<HttpResponse<void>> {
    const url: string = `${this._resourceUrl}/${inspectionId}/sampling-sizes`;

    return this._httpClient.patch<void>(url, samplingSizes, { observe: 'response' });
  }

  public updateInspectionActualQuantities$(
    inspectionId: Readonly<InspectionId>,
    productActualQuantities: Readonly<IProductActualQuantities>
  ): Observable<HttpResponse<void>> {
    const url: string = `${this._resourceUrl}/${inspectionId}/workflow/actual-quantity`;

    return this._httpClient.put<void>(url, productActualQuantities, { observe: 'response' });
  }

  public updateInspectionRecipients$(inspectionId: Readonly<InspectionId>, recipients: Readonly<string[]>): Observable<HttpResponse<void>> {
    const url: string = `${this._resourceUrl}/${inspectionId}/recipients`;

    return this._httpClient.patch<void>(url, { ccRecipients: recipients }, { observe: 'response' });
  }

  public createDefect$(
    inspectionId: InspectionId,
    name: string,
    type: string,
    stepId: string,
    classification: DefectClassification,
    purchaseOrderProductId?: number
  ): Observable<IDefectsChecklistDefect> {
    const url = `${this._resourceUrl}/${inspectionId}/defects`;
    const body: INewDefectInfo = {
      name,
      type,
      stepId,
      classification,
      purchaseOrderProductId,
    };

    return this._httpClient.post<IDefectsChecklistDefect>(url, body, { observe: 'body' });
  }

  public completeDefectsChecklist$(
    inspectionId: Readonly<InspectionId>,
    stepId: number,
    actionId: number,
    purchaseOrderProductId?: number
  ): Observable<void> {
    const url = `${this._resourceUrl}/${inspectionId}/complete-defects-checklist/steps/${stepId}/actions/${actionId}`;

    return this._httpClient.put<void>(url, { purchaseOrderProductId }, { observe: 'body' });
  }

  public completeStartingPictures$(inspectionId: Readonly<InspectionId>, pathId: string): Observable<void> {
    const url = `${this._resourceUrl}/${inspectionId}/starting-pictures/${pathId}/validation`;

    return this._httpClient.put<void>(
      url,
      {
        date: QpDateService.now,
        timezone: QpDateService.timezone,
      },
      { observe: 'body' }
    );
  }

  public completeReferenceSample$(
    inspectionId: Readonly<InspectionId>,
    stepId: string,
    actionId: string,
    stepCompletionTime: IIspInspectionIdStepCompletionTime
  ): Observable<void> {
    const url = `${this._resourceUrl}/${inspectionId}/complete-reference-sample/steps/${stepId}/actions/${actionId}`;

    return this._httpClient.put<void>(url, stepCompletionTime);
  }

  public completeSampleCollection$(
    inspectionId: Readonly<InspectionId>,
    stepId: string,
    actionId: string,
    stepCompletionTime: IIspInspectionIdStepCompletionTime
  ): Observable<void> {
    const url = `${this._resourceUrl}/${inspectionId}/complete-sample-collection/steps/${stepId}/actions/${actionId}`;

    return this._httpClient.put<void>(url, stepCompletionTime);
  }

  public confirmFindingsSummaryReview$(inspectionId: InspectionId): Observable<void> {
    const url = `${this._resourceUrl}/${inspectionId}/findings-summary-review`;
    const body: IFindingsSummaryReviewConfirmationDTO = {
      confirmationTime: this._qpDateService.getDate(),
      confirmationTimezone: this._qpDateService.getTimezone(),
    };

    return this._httpClient.put<void>(url, body);
  }

  public updateDefect$(
    inspectionId: Readonly<InspectionId>,
    defectId: Readonly<string>,
    quantity: Readonly<number>,
    classification: Readonly<string>,
    purchaseOrderProductId?: Readonly<number>
  ): Observable<void> {
    const url = `${this._resourceUrl}/${inspectionId}/defects/${defectId}`;

    return this._httpClient.put<void>(url, {
      quantity,
      classification,
      purchaseOrderProductId,
    });
  }

  public updateDefectComment$(
    inspectionId: InspectionId,
    defectId: string,
    comment: IChecklistComment,
    purchaseOrderProductId?: QimaOptionalType<number>
  ): Observable<void> {
    const url = `${this._resourceUrl}/${inspectionId}/defects/${defectId}/comment`;

    return this._httpClient.patch<void>(url, { ...comment, purchaseOrderProductId });
  }

  public addInspectionAttachment$(inspectionId: InspectionId, blob: Blob, entity: string, entityId?: string): Observable<string> {
    const attachmentId: uuid = uuidv4();
    const url =
      entity === EQpImageElementType.SIMPLIFIED_MEASUREMENTS
        ? `${this._resourceUrl}/${inspectionId}/simplified-measurement/attachments`
        : `${this._resourceUrl}/${inspectionId}/${entity}/${entityId}/attachments`;
    const formData = new FormData();

    this._increaseNumberOfPendingItemsToSave();

    formData.append('attachment', blob);

    return this._httpClient
      .post(url, formData, {
        params: { id: attachmentId },
        observe: 'response',
      })
      .pipe(
        map(getGeneratedEntityId),
        finalize((): void => this._decreaseNumberOfPendingItemsToSave())
      );
  }

  public deleteInspectionAttachment$(
    inspectionId: InspectionId,
    entity: string,
    attachmentId: string,
    entityId?: string
  ): Observable<unknown> {
    const url =
      entity === EQpImageElementType.SIMPLIFIED_MEASUREMENTS
        ? `${this._resourceUrl}/${inspectionId}/simplified-measurement/attachments/${attachmentId}`
        : `${this._resourceUrl}/${inspectionId}/${entity}/${entityId}/attachments/${attachmentId}`;

    return this._httpClient.delete(url);
  }

  public addOrUpdateInspectionPicture$(
    inspectionId: InspectionId,
    blob: Blob,
    data: IChecklistImageData,
    entity?: QimaOptionalType<string>,
    entityId?: QimaOptionalType<string>
  ): Observable<string> {
    let url: string;

    if (!data.imageId) {
      data.imageId = uuidv4();
    }

    this._increaseNumberOfPendingItemsToSave();

    if (!data.mimeType) {
      data.mimeType = blob.type;
    }

    if (entity && entityId) {
      url = `${this._resourceUrl}/${inspectionId}/${entity}/${entityId}/images?id=${data.imageId}`;
    } else {
      url = `${this._resourceUrl}/${inspectionId}/images?id=${data.imageId}`;
    }

    return this._imageCompressionService.resizeAndCompressImage$(<File>blob).pipe(
      catchError((error: Error | unknown): Observable<never> => {
        if (error instanceof Error) {
          this._qpLoggerService.error(`Error while resizing/compressing uploading image for inspection ${inspectionId}`, error.message);
        } else {
          this._qpLoggerService.error(`Unexpected error while resizing/compressing image for inspection ${inspectionId}`, error);
        }

        return throwError(error);
      }),
      mergeMap((resizedBlob): Observable<string> => {
        const formData = new FormData();

        formData.append('image', resizedBlob);
        formData.append('data', new Blob([JSON.stringify(data)], { type: 'application/json' }));

        return this._httpClient.post(url, formData, { observe: 'response' }).pipe(map(getGeneratedEntityId));
      }),
      finalize((): void => this._decreaseNumberOfPendingItemsToSave())
    );
  }

  public updatePictureData$(
    inspectionId: InspectionId,
    imageId: string,
    data: Partial<IChecklistImageData>,
    entity: QimaOptionalType<string>,
    entityId: QimaOptionalType<string>,
    purchaseOrderProductId: QimaOptionalType<number>
  ): Observable<void> {
    const url = `${this._resourceUrl}/${inspectionId}/${!isNil(entity) ? `${entity}/${entityId}/` : ''}images/${imageId}`;

    return this._httpClient.patch<void>(url, {
      date: this._qpDateService.getDate(),
      timezone: this._qpDateService.getTimezone(),
      purchaseOrderProductId,
      ...data,
    });
  }

  public updatePicturesData$(
    inspectionId: InspectionId,
    imageIds: string[],
    data: { caption?: string; isSpotlight?: boolean; isLinkedToTestResult?: boolean },
    entity: QimaOptionalType<string>,
    entityId: QimaOptionalType<string>,
    purchaseOrderProductId: QimaOptionalType<number>
  ): Observable<void> {
    const url = `${this._resourceUrl}/${inspectionId}/${!isNil(entity) ? `${entity}/${entityId}/` : ''}images/bulk`;

    return this._httpClient.patch<void>(url, {
      date: this._qpDateService.getDate(),
      timezone: this._qpDateService.getTimezone(),
      imageIds,
      purchaseOrderProductId,
      data,
    });
  }

  public changePicturesOrder$(
    inspectionId: InspectionId,
    pictureOrder: string[],
    entity?: QimaOptionalType<string>,
    entityId?: QimaOptionalType<string>,
    purchaseOrderProductId?: QimaOptionalType<number>
  ): Observable<void> {
    const url =
      entity && entityId
        ? `${this._resourceUrl}/${inspectionId}/${entity}/${entityId}/images/order`
        : `${this._resourceUrl}/${inspectionId}/images/order`;

    return this._httpClient.put<void>(url, {
      date: this._qpDateService.getDate(),
      timezone: this._qpDateService.getTimezone(),
      imageIds: pictureOrder,
      purchaseOrderProductId,
    });
  }

  public getInspectionElementPicture$({
    inspectionId,
    imageId,
    entity,
    entityId,
    isThumbnail = true,
    options,
  }: IInspectionServiceGetInspectionElementImageData): Observable<Picture> {
    const url = `${this._resourceUrl}/${inspectionId}/${entity}/${entityId}/images/${imageId}`;

    return this._httpClient
      .get(url, {
        responseType: 'arraybuffer',
        observe: 'response',
        params: isThumbnail ? { thumbnail: this._getThumbnailsSize() } : null,
        headers: getCustomHttpHeaders(options),
      })
      .pipe(
        map((response): Picture => {
          return {
            id: imageId,
            type: response.headers.get('content-type'),
            rawData: response.body,
            data: JSON.parse(fromBase64(response.headers.get('data')) || '{}'),
          };
        })
      );
  }

  public getWorkflow$(
    id: Readonly<InspectionId>,
    isWithoutCache: Readonly<boolean> = false,
    options?: Readonly<IHttpOptions>
  ): Observable<QimaOptionalType<IWorkflow>> {
    const url = `${this._resourceUrl}/${id}/workflow`;
    const headers = getCustomHttpHeaders(options);

    if (isWithoutCache) {
      headers.append(INTERCEPTOR_SKIP_HEADER, INTERCEPTOR_SKIP_HEADER);
    }

    return this._httpClient
      .get<QimaOptionalType<IWorkflow>>(url, {
        observe: 'body',
        headers,
      })
      .pipe(
        map((workflow: Readonly<QimaOptionalType<IWorkflow>>): QimaOptionalType<IWorkflow> => {
          return workflow ? WorkflowUtils.addSectionToWorkflowMeasurementChecklist(workflow) : undefined;
        })
      );
  }

  public getAllInspectionTypesDifferentOf$(inspectionTypeToExclude: string): Observable<InspectionTypeDTO[]> {
    return this.getAllInspectionTypes$().pipe(
      map((inspectionTypes: InspectionTypeDTO[]): InspectionTypeDTO[] =>
        inspectionTypes.filter((inspectionType: InspectionTypeDTO): boolean => inspectionType.label !== inspectionTypeToExclude)
      )
    );
  }

  public getAllInspectionTypes$(): Observable<InspectionTypeDTO[]> {
    return this._httpClient.get<InspectionTypeDTO[]>(`${SERVER_API_URL}api/inspection-types`, { observe: 'body' });
  }

  public getProductsTotalQuantity(products: IInspectionProduct[]): number {
    if (isEmpty(products)) {
      return 0;
    }

    return products
      .map((p: IInspectionProduct): number => p.productQuantity ?? 0)
      .reduce((previousValue = 0, currentValue): number => {
        return previousValue + currentValue;
      });
  }

  public getUpcomingInspections$(): Observable<IInspectionsSummary[]> {
    return this._httpClient.get<IInspectionsSummary[]>(`${SERVER_API_URL}api/inspections/summary?q=TODAY&q=FUTURE`, { observe: 'body' });
  }

  public getInspectionDraftId$(): Observable<{ id: QpUuidType }> {
    return this._httpClient.get<{ id: QpUuidType }>(`${SERVER_API_URL}api/inspection-drafts/id`, { observe: 'body' });
  }

  public addActionDocument$(inspectionId: InspectionId, file: File): Observable<HttpResponse<void>> {
    const formData = new FormData();
    const document = file.name.toLowerCase().endsWith('pdf')
      ? Promise.resolve(file)
      : this._imageCompressionService.resizeAndCompressImage$(file);

    return from(document).pipe(
      catchError((error: Error): Observable<never> => {
        this._qpLoggerService.error(`Error while resizing/compressing uploading document for inspection ${inspectionId}`, error.message);

        return throwError(error);
      }),
      mergeMap((resizedBlob): Observable<HttpResponse<void>> => {
        formData.append('file', resizedBlob, file.name);
        const uuid: uuid = uuidv4();

        this._increaseNumberOfPendingItemsToSave();

        return this._httpClient
          .post<void>(`${this._resourceUrl}/${inspectionId}/documents?id=${uuid}`, formData, {
            observe: 'response',
          })
          .pipe(finalize((): void => this._decreaseNumberOfPendingItemsToSave()));
      })
    );
  }

  public getDocumentByInspectionIdOrGenerateId$(
    documentLocationId: number,
    action: IWorkflowTemplateStepAction | IEntityWorkflowTemplateStepAction
  ): Observable<IWorkflowTemplateStepAction | IEntityWorkflowTemplateStepAction> {
    return this._httpClient.get(`${this._resourceUrl}/${documentLocationId}/documents/${action.documentId}/metadata`).pipe(
      map((document: IWorkflowTemplateStepActionDocument): IWorkflowTemplateStepAction | IEntityWorkflowTemplateStepAction => {
        action.document = document;

        return action;
      })
    );
  }

  public getDocument$(
    id: InspectionId,
    documentId: string,
    documentType: EInspectionDocumentType = EInspectionDocumentType.GLOBAL
  ): Observable<{ data: ArrayBuffer; type: string }> {
    return this._httpClient
      .get(`${SERVER_API_URL}api/${documentType}/${id}/documents/${documentId}`, {
        responseType: 'arraybuffer',
        observe: 'response',
        headers: getCustomHttpHeaders(HTTP_SKIP_404_OPTIONS),
      })
      .pipe(
        map((response): { data: ArrayBuffer; type: string } => {
          return {
            type: response.headers.get('content-type'),
            data: response.body,
          };
        })
      );
  }

  public assignInspector$(inspectionId: InspectionId, inspectorId: number): Observable<HttpResponse<void>> {
    return this._httpClient.patch<void>(`${SERVER_API_URL}api/inspections/${inspectionId}`, { id: inspectorId }, { observe: 'response' });
  }

  public changeSite$(inspectionId: InspectionId, entityId: number): Observable<HttpResponse<void>> {
    return this._httpClient.patch<void>(
      `${SERVER_API_URL}api/inspections/${inspectionId}/change-site/${entityId}`,
      {},
      { observe: 'response' }
    );
  }

  public getReportDefectsAllowedFromSampleSize$(
    sampleSize: Readonly<number>,
    critical: AqlValue,
    major: AqlValue,
    minor: AqlValue
  ): Observable<IMaxDefectsAllowed> {
    const url: string = `${SERVER_API_URL}api/inspections/report/aql`;

    return this._httpClient.get<IMaxDefectsAllowed>(url, {
      observe: 'body',
      params: {
        sampleSize: sampleSize.toString(),
        critical: critical.toString(),
        major: major.toString(),
        minor: minor.toString(),
      },
    });
  }

  public getReportDefectsAllowed$(
    criticalSampleSize: Readonly<number>,
    majorSampleSize: Readonly<number>,
    minorSampleSize: Readonly<number>,
    critical: AqlValue,
    major: AqlValue,
    minor: AqlValue
  ): Observable<IMaxDefectsAllowed> {
    const url: string = `${SERVER_API_URL}api/inspections/report/aql`;

    return this._httpClient.get<IMaxDefectsAllowed>(url, {
      observe: 'body',
      params: {
        criticalSampleSize: criticalSampleSize.toString(),
        majorSampleSize: majorSampleSize.toString(),
        minorSampleSize: minorSampleSize.toString(),
        critical: critical.toString(),
        major: major.toString(),
        minor: minor.toString(),
      },
    });
  }

  public deleteInspectionImage$({
    inspectionId,
    imageElementType,
    elementId,
    data,
  }: IDeleteWorkflowImage): Observable<HttpResponse<Record<string, unknown>>> {
    let url: string;

    if (imageElementType && imageElementType !== EQpImageElementType.PRODUCT_PICTURE) {
      url = `${this._resourceUrl}/${inspectionId}/${imageElementType}/${elementId}/images`;
    } else {
      url = `${this._resourceUrl}/${inspectionId}/images`;
    }

    return this._httpClient.request<Record<string, unknown>>('delete', url, {
      observe: 'response',
      body: data,
    });
  }

  public deleteInspection$(id: Readonly<InspectionId>): Observable<unknown> {
    return this._httpClient.delete(`${this._resourceUrl}/${id}`);
  }

  public getCancellationReasons$(): Observable<string[]> {
    return this._httpClient.get<string[]>(`${this._resourceUrl}/cancellation-reasons`);
  }

  public getRequestForChangeReasons$(): Observable<string[]> {
    return this._httpClient.get<string[]>(`${this._resourceUrl}/change-request-reasons`);
  }

  public updateInspectionDate$(inspectionId: InspectionId, inspectionDate: string): Observable<void> {
    return this._httpClient.patch<void>(`${this._resourceUrl}/${inspectionId}/date`, { inspectionDate });
  }

  public saveSimplifiedMeasures$(
    inspectionId: InspectionId,
    simplifiedMeasures: SimplifiedMeasurementChecklistMeasuresDTO
  ): Observable<void> {
    const url: string = `${this._resourceUrl}/${inspectionId}/simplified-measurement/measurement`;

    return this._httpClient.put<void>(url, simplifiedMeasures);
  }

  public saveSimplifiedMeasurementSampling$(inspectionId: InspectionId, sampling: IMeasurementSampling): Observable<void> {
    const url: string = `${this._resourceUrl}/${inspectionId}/simplified-measurement/sampling`;

    return this._httpClient.put<void>(url, sampling);
  }

  public saveSimplifiedMeasurementSamplingModificationReason(inspectionId: InspectionId, reason: string): Observable<void> {
    const url: string = `${this._resourceUrl}/${inspectionId}/simplified-measurement/sampling/modification-reason`;

    return this._httpClient.put<void>(url, { reason });
  }

  public saveSimplifiedMeasuresMarkedAsNotApplicable$(
    inspectionId: InspectionId,
    status: SimplifiedMeasurementChecklistStatusDTO
  ): Observable<void> {
    const url: string = `${this._resourceUrl}/${inspectionId}/simplified-measurement/status`;

    return this._httpClient.put<void>(url, status);
  }

  public saveSimplifiedMeasurementComment$(inspectionId: InspectionId, comment: string): Observable<void> {
    const url: string = `${this._resourceUrl}/${inspectionId}/simplified-measurement/comment`;

    return this._httpClient.put<void>(url, { comment });
  }

  private _getThumbnailsSize(): EQpImageThumbnailSize.S128 | EQpImageThumbnailSize.S256 {
    return this._qpResponsiveService.isMobile() ? EQpImageThumbnailSize.S128 : EQpImageThumbnailSize.S256;
  }

  private readonly _increaseNumberOfPendingItemsToSave = (): void =>
    this.numberOfPendingItemsToSave$.next(this.numberOfPendingItemsToSave$.value + 1);

  private readonly _decreaseNumberOfPendingItemsToSave = (): void =>
    this.numberOfPendingItemsToSave$.next(this.numberOfPendingItemsToSave$.value - 1);
}
